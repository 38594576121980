@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Source Sans Pro', Arial, Helvetica Neue, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  height: 100%;
}

#root {
  min-height: 100%;
}

html {
  overflow: auto;
  min-height: 100%;
}
